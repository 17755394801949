<template>
  <IngredientsOrder
    disable-team-filter
    do-not-load-team-assignments
    paging
    exclude-allergy
    show-export-button
    :data-as-table-function="dataAsTable"
    date-range-picker
  />
</template>


<script>
import IngredientsOrder from '@/components/IngredientsOrder';
import {formatWeightWithUnits} from "@/store/utils";


export default {
  components: {IngredientsOrder},
  methods: {
    dataAsTable(ingredients, header, search) {
      console.log('dataAsTable', {ingredients, header, search});
      return ingredients.map(i => ({
          'buy amount needed': formatWeightWithUnits(i.buyAmountValue),
          'raw amount needed': formatWeightWithUnits(i.rawAmountValue),
          name: i.name.split(':::')[0],
          description: i.name.split(':::')[1] || '',
          category: i.category,
          note: i.note || ''
        })
      );
    }
  }
}
</script>
